import contact from "../utils/json/contact.json"
import Section from "../components/Section";
import "../assets/scss/pages/contact.scss"

export default function Contact() {
    const className = "contact"

    return (
        <main className={className}>
            {
                contact.map((section) => (
                    <Section key={section.id} classN={`${className}__section`} data={section.contents} />
                ))
            }
        </main>
    )
}