import Title from "./Title";
import Link from "./Link";
import Picture from "./Picture";
import Paragraph from "./Paragraph";
import picture from "../assets/pictures/profile.webp";

export default function Section({classN, data, onClick, refs}) {
    const className = classN
    console.log(classN)

    return (
            <section className={classN && className} ref={className === "home__profile" ? refs : null}>
                {
                    data.map((content) => (
                       content.type === "title"
                        ?
                        <Title key={content.id} classN={className} size={content.size} content={content.content}/>
                        :
                        content.type === "link"
                        ?
                        <Link key={content.id} classN={className} link={content.link} content={content.content} onClick={onClick}/>
                        :
                        content.type === "picture"
                        ?
                        <Picture key={content.id} classN={className} picture={picture}/>
                        :
                        content.type === "paragraph"
                        ?
                        <Paragraph key={content.id} classN={className} content={content.content}/>
                        :
                        null

                    ))
                }
            </section>
    )
}