import React from "react";
import { useSelector } from 'react-redux';

export default function Title({classN, size, content}) {
    const className = `${classN}__title`
    const name = useSelector(state => state.name);

    return (
        size === "h1"
        ? <h1 style={{filter: name && "blur(8px)", pointerEvents: name && "none"}} className={className}>{content}</h1>
        : size === "h2"
        ? <h2 style={{filter: name && "blur(8px)", pointerEvents: name && "none"}} className={className}>{content}</h2>
        : size === "h3"
        ? <h3 style={{filter: name && "blur(8px)", pointerEvents: name && "none"}} className={className}>{content}</h3>
        : null
    )

}