import "../../assets/scss/base/footer.scss"
import Copyright from "./Copyright";
import { useSelector } from 'react-redux';

export default function Footer() {
    const className = "footer"
    const name = useSelector(state => state.name);

    return (
        <footer style={{filter: name && "blur(8px)", pointerEvents: name && "none"}} className={className}>
            <Copyright classN={className} />
        </footer>
    )
}