import Link from "../Link";
import Picture from "../Picture";
import picture from "../../assets/pictures/profile.webp";
import Hamburger from "./Hamburger";
import {useState} from "react";

export default function NavBar({classN, link}) {
    const className = `${classN}__navbar`

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen)
    }

    return (
        <nav className={className}>
            <ul>
                <li className={"linkMasked"}>
                    <a href="/public"><Picture classN={className} picture={picture} /></a>
                </li>
                {
                    link.map(({href, name}) => (
                        <li key={name}>
                            <Link classN={className} content={name} link={href} />
                        </li>
                    ))
                }
            </ul>

            <div className={`${className}__hamburger`} onClick={toggleHamburger}>
                <Hamburger isOpen={hamburgerOpen} />
            </div>

            <style>{`
              @media screen and (max-width: 920px) {
                .header__navbar ul li:not(:nth-child(1)) {
                  transform: ${hamburgerOpen ? 'translateY(0)' : 'translateY(-500%)'};
                  height: ${hamburgerOpen ? '100%' : '0'};
                  overflow: hidden;
                  z-index: ${hamburgerOpen ? '9999' : '-9999'};
                }
                
                .header__navbar ul {
                  height: ${hamburgerOpen ? '100vh' : 'auto'};
                }

                .header {
                  height: ${hamburgerOpen ? '100%' : '94px'};
                }
              }
            `}</style>
        </nav>
    )
}