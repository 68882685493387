import React, {useEffect} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch } from 'react-redux';
import {deleteNameAction} from "../../utils/redux/actions/getName";

export default function Carousel({classN, name, carouselData}) {
    const className = `${classN}__carousel`;
    const selectedCarouselItem = carouselData.find(item => item.name === name);
    const dispatch = useDispatch();

    const closeCarousel = () => {
        dispatch(deleteNameAction());
    };

    useEffect(() => {
        const elements = document.querySelectorAll('.slick-arrow'); // Remplacez 'maClasse' par le nom de votre classe
        
        if (name && selectedCarouselItem.img.length < 2) {
            elements.forEach(element => {
                element.style.display = 'none';
            });
        }
    }, [name, selectedCarouselItem]);


    const settings = {
        dots: true,
        infinite: !(name && selectedCarouselItem.img.length < 2),
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            {
                selectedCarouselItem &&
                    (
                        <div style={{display: selectedCarouselItem ? "block" : "none"}} className={`${className} carousel-overlay`}>
                            <div className="carousel-close" onClick={closeCarousel}><p>X</p></div>
                            <Slider {...settings}>
                                {
                                    selectedCarouselItem &&
                                        selectedCarouselItem.img.map((pict, index) => (<div key={index}><img src={pict} alt={pict}/></div>))
                                }
                            </Slider>
                        </div>
                    )
            }
        </>
    )
}

//selectedCarouselItem.img.map((pict, index) => (<div key={index}><img src={pict} alt={pict}/></div>))