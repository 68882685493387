import React from 'react';
import { useDispatch } from 'react-redux';
import { nameAction } from '../../utils/redux/actions/getName';
import project_1 from "../../assets/pictures/projects/La planche à vins.webp";
import project_2 from "../../assets/pictures/projects/Transformez une maquette en site web avec HTML et CSS 1.webp";
import project_3 from "../../assets/pictures/projects/Dynamisez une page web avec des animations CSS 1.webp";
import project_4 from "../../assets/pictures/projects/Créez une landing page avec Javascript 1.webp";
import project_5 from "../../assets/pictures/projects/Créez un site accessible pour une plateforme de photographes 1.webp";
import project_6 from "../../assets/pictures/projects/Développez un algorithme de recherche en JavaScript 1.webp";
import project_7 from "../../assets/pictures/projects/Débuggez et testez un SaaS RH 1.webp";
import project_8 from "../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Diagramme cas dusage) 1.webp";
import project_9 from "../../assets/pictures/projects/Développez une application Web avec React et React Router 1.webp";
import project_10 from "../../assets/pictures/projects/Développez un tableau de bord danalytics avec React 1.webp";
import project_11 from "../../assets/pictures/projects/Utilisez une API pour un compte utilisateur bancaire avec React 1.webp";
import project_12 from "../../assets/pictures/projects/Faites passer une librairie jQuery vers React 1.webp";
import project_13 from "../../assets/pictures/projects/ETS Gaveriaux Raywan.webp";
import project_14 from "../../assets/pictures/projects/Mon Portfolio.webp";

const ImageGallery = ({classN, pict}) => {
    const className = `${classN}__img`;
    const dispatch = useDispatch();

    const handleImageClick = (event) => {
        const name = event.target.name;
        dispatch(nameAction(name));
    };

    const data = [
        {
            "name": "La Planche à Vins",
            "img": project_1
        },
        {
            "name": "Transformez une maquette en site web avec HTML et CSS",
            "img": project_2
        },
        {
            "name": "Dynamisez une page web avec des animations CSS",
            "img": project_3
        },
        {
            "name": "Créez une landing page avec Javascript",
            "img": project_4
        },
        {
            "name": "Créez un site accessible pour une plateforme de photographes",
            "img": project_5
        },
        {
            "name": "Développez un algorithme de recherche en JavaScript",
            "img": project_6
        },
        {
            "name": "Débuggez et testez un SaaS RH",
            "img": project_7
        },
        {
            "name": "Définissez les besoins pour une app de soutien scolaire",
            "img": project_8
        },
        {
            "name": "Développez une application Web avec React et React Router",
            "img": project_9
        },
        {
            "name": "Développez un tableau de bord analytics avec React",
            "img": project_10
        },
        {
            "name": "Utilisez une API pour un compte utilisateur bancaire avec React",
            "img": project_11
        },
        {
            "name": "Faites passer une librairie jQuery vers React",
            "img": project_12
        },
        {
            "name": "ETS Gaveriaux Raywan",
            "img": project_13
        },
        {
            "name": "Mon Portfolio",
            "img": project_14
        }
    ];


    return (
        <>
            {data.map((item) => (
                item.name === pict
                    ? (<img key={item.name} className={className} name={item.name} onClick={handleImageClick} src={item.img} alt={item.img}/>)
                    : ""
            ))}

        </>
    );
};

export default ImageGallery;