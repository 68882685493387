import NavBar from "./NavBar";
import link from "../../utils/json/NavBarLink.json"
import "../../assets/scss/base/header.scss"
import { useSelector } from 'react-redux';


export default function Header() {
    const className = "header"
    const name = useSelector(state => state.name);

    return (
        <header style={{filter: name && "blur(8px)", pointerEvents: name && "none"}} className={className}>
            <NavBar classN={className} link={link} />
        </header>
    )
}