export const nameAction = (name) => {
    return {
        type: 'GET_NAME',
        payload: name
    };
};

export const deleteNameAction = () => {
    return {
        type: 'DEL_NAME'
    };
};