import La_planche_a_vins from "../../../assets/pictures/projects/La planche à vins.webp";
import Transformez_une_maquette_en_site_web_avec_HTML_et_CSS_1 from "../../../assets/pictures/projects/Transformez une maquette en site web avec HTML et CSS 1.webp";
import Transformez_une_maquette_en_site_web_avec_HTML_et_CSS_2 from "../../../assets/pictures/projects/Transformez une maquette en site web avec HTML et CSS 2.webp";
import Dynamisez_une_page_web_avec_des_animations_CSS_1 from "../../../assets/pictures/projects/Dynamisez une page web avec des animations CSS 1.webp";
import Dynamisez_une_page_web_avec_des_animations_CSS_2 from "../../../assets/pictures/projects/Dynamisez une page web avec des animations CSS 2.webp";
import Creez_une_landing_page_avec_Javascript_1 from "../../../assets/pictures/projects/Créez une landing page avec Javascript 1.webp";
import Creez_une_landing_page_avec_Javascript_2 from "../../../assets/pictures/projects/Créez une landing page avec Javascript 2.webp";
import Creez_une_landing_page_avec_Javascript_3 from "../../../assets/pictures/projects/Créez une landing page avec Javascript 3.webp";
import Creez_une_landing_page_avec_Javascript_4 from "../../../assets/pictures/projects/Créez une landing page avec Javascript 4.webp";
import Creez_un_site_accessible_pour_une_plateforme_de_photographes_1 from "../../../assets/pictures/projects/Créez un site accessible pour une plateforme de photographes 1.webp";
import Creez_un_site_accessible_pour_une_plateforme_de_photographes_2 from "../../../assets/pictures/projects/Créez un site accessible pour une plateforme de photographes 2.webp";
import Creez_un_site_accessible_pour_une_plateforme_de_photographes_3 from "../../../assets/pictures/projects/Créez un site accessible pour une plateforme de photographes 3.webp";
import Creez_un_site_accessible_pour_une_plateforme_de_photographes_4 from "../../../assets/pictures/projects/Créez un site accessible pour une plateforme de photographes 4.webp";
import Creez_un_site_accessible_pour_une_plateforme_de_photographes_5 from "../../../assets/pictures/projects/Créez un site accessible pour une plateforme de photographes 5.webp";
import Creez_un_site_accessible_pour_une_plateforme_de_photographes_6 from "../../../assets/pictures/projects/Créez un site accessible pour une plateforme de photographes 6.webp";
import Developpez_un_algorithme_de_recherche_en_JavaScript_1 from "../../../assets/pictures/projects/Développez un algorithme de recherche en JavaScript 1.webp";
import Developpez_un_algorithme_de_recherche_en_JavaScript_2 from "../../../assets/pictures/projects/Développez un algorithme de recherche en JavaScript 2.webp";
import Developpez_un_algorithme_de_recherche_en_JavaScript_3 from "../../../assets/pictures/projects/Développez un algorithme de recherche en JavaScript 3.webp";
import Developpez_un_algorithme_de_recherche_en_JavaScript_4 from "../../../assets/pictures/projects/Développez un algorithme de recherche en JavaScript 4.webp";
import Developpez_un_algorithme_de_recherche_en_JavaScript_5 from "../../../assets/pictures/projects/Développez un algorithme de recherche en JavaScript 5.webp";
import Developpez_un_algorithme_de_recherche_en_JavaScript_6 from "../../../assets/pictures/projects/Développez un algorithme de recherche en JavaScript 6.webp";
import Debuggez_et_testez_un_SaaS_RH_1 from "../../../assets/pictures/projects/Débuggez et testez un SaaS RH 1.webp";
import Debuggez_et_testez_un_SaaS_RH_2 from "../../../assets/pictures/projects/Débuggez et testez un SaaS RH 2.webp";
import Debuggez_et_testez_un_SaaS_RH_3 from "../../../assets/pictures/projects/Débuggez et testez un SaaS RH 3.webp";
import Debuggez_et_testez_un_SaaS_RH_4 from "../../../assets/pictures/projects/Débuggez et testez un SaaS RH 4.webp";
import Debuggez_et_testez_un_SaaS_RH_5 from "../../../assets/pictures/projects/Débuggez et testez un SaaS RH 5.webp";
import Debuggez_et_testez_un_SaaS_RH_6 from "../../../assets/pictures/projects/Débuggez et testez un SaaS RH 6.webp";
import Debuggez_et_testez_un_SaaS_RH_7 from "../../../assets/pictures/projects/Débuggez et testez un SaaS RH 7.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_1 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Diagramme cas dusage) 1.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_2 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Diagramme cas dusage) 2.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_3 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Diagramme cas dusage) 3.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_4 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (User stories) 4.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_5 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (User stories) 5.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_6 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (User stories) 6.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_7 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (User stories) 7.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_8 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Maquettes) 8.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_9 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Maquettes) 9.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_10 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Maquettes) 10.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_11 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Maquettes) 11.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_12 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Maquettes) 12.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_13 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Kanban) 13.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_14 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Kanban) 14.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_15 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Kanban) 15.webp";
import Definissez_les_besoins_pour_une_app_de_soutien_scolaire_16 from "../../../assets/pictures/projects/Définissez les besoins pour une app de soutien scolaire (Kanban) 16.webp";
import Developpez_une_application_Web_avec_React_et_React_Router_1 from "../../../assets/pictures/projects/Développez une application Web avec React et React Router 1.webp";
import Developpez_une_application_Web_avec_React_et_React_Router_2 from "../../../assets/pictures/projects/Développez une application Web avec React et React Router 2.webp";
import Developpez_une_application_Web_avec_React_et_React_Router_3 from "../../../assets/pictures/projects/Développez une application Web avec React et React Router 3.webp";
import Developpez_une_application_Web_avec_React_et_React_Router_4 from "../../../assets/pictures/projects/Développez une application Web avec React et React Router 4.webp";
import Developpez_un_tableau_de_bord_analytics_avec_React_1 from "../../../assets/pictures/projects/Développez un tableau de bord danalytics avec React 1.webp";
import Developpez_un_tableau_de_bord_analytics_avec_React_2 from "../../../assets/pictures/projects/Développez un tableau de bord danalytics avec React 2.webp";
import Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_1 from "../../../assets/pictures/projects/Utilisez une API pour un compte utilisateur bancaire avec React 1.webp";
import Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_2 from "../../../assets/pictures/projects/Utilisez une API pour un compte utilisateur bancaire avec React 2.webp";
import Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_3 from "../../../assets/pictures/projects/Utilisez une API pour un compte utilisateur bancaire avec React 3.webp";
import Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_4 from "../../../assets/pictures/projects/Utilisez une API pour un compte utilisateur bancaire avec React 4.webp";
import Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_5 from "../../../assets/pictures/projects/Utilisez une API pour un compte utilisateur bancaire avec React 5.webp";
import Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_6 from "../../../assets/pictures/projects/Utilisez une API pour un compte utilisateur bancaire avec React 6.webp";
import Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_7 from "../../../assets/pictures/projects/Utilisez une API pour un compte utilisateur bancaire avec React 7.webp";
import Faites_passer_une_librairie_jQuery_vers_React_1 from "../../../assets/pictures/projects/Faites passer une librairie jQuery vers React 1.webp";
import Faites_passer_une_librairie_jQuery_vers_React_2 from "../../../assets/pictures/projects/Faites passer une librairie jQuery vers React 2.webp";
import Faites_passer_une_librairie_jQuery_vers_React_3 from "../../../assets/pictures/projects/Faites passer une librairie jQuery vers React 3.webp";
import Faites_passer_une_librairie_jQuery_vers_React_4 from "../../../assets/pictures/projects/Faites passer une librairie jQuery vers React 4.webp";
import Faites_passer_une_librairie_jQuery_vers_React_5 from "../../../assets/pictures/projects/Faites passer une librairie jQuery vers React 5.webp";
import Faites_passer_une_librairie_jQuery_vers_React_6 from "../../../assets/pictures/projects/Faites passer une librairie jQuery vers React 6.webp";
import ETS_Gaveriaux_Raywan from "../../../assets/pictures/projects/ETS Gaveriaux Raywan.webp";
import Mon_Portfolio from "../../../assets/pictures/projects/Mon Portfolio.webp";


const initialState = {
    name: '',
    carouselData: [
        {
            "name": "La Planche à Vins",
            "img": [La_planche_a_vins]
        },
        {
            "name": "Transformez une maquette en site web avec HTML et CSS",
            "img": [Transformez_une_maquette_en_site_web_avec_HTML_et_CSS_1, Transformez_une_maquette_en_site_web_avec_HTML_et_CSS_2]
        },
        {
            "name": "Dynamisez une page web avec des animations CSS",
            "img": [Dynamisez_une_page_web_avec_des_animations_CSS_1, Dynamisez_une_page_web_avec_des_animations_CSS_2]
        },
        {
            "name": "Créez une landing page avec Javascript",
            "img": [Creez_une_landing_page_avec_Javascript_1, Creez_une_landing_page_avec_Javascript_2, Creez_une_landing_page_avec_Javascript_3, Creez_une_landing_page_avec_Javascript_4]
        },
        {
            "name": "Créez un site accessible pour une plateforme de photographes",
            "img": [Creez_un_site_accessible_pour_une_plateforme_de_photographes_1, Creez_un_site_accessible_pour_une_plateforme_de_photographes_2, Creez_un_site_accessible_pour_une_plateforme_de_photographes_3, Creez_un_site_accessible_pour_une_plateforme_de_photographes_4, Creez_un_site_accessible_pour_une_plateforme_de_photographes_5, Creez_un_site_accessible_pour_une_plateforme_de_photographes_6]
        },
        {
            "name": "Développez un algorithme de recherche en JavaScript",
            "img": [Developpez_un_algorithme_de_recherche_en_JavaScript_1, Developpez_un_algorithme_de_recherche_en_JavaScript_2, Developpez_un_algorithme_de_recherche_en_JavaScript_3, Developpez_un_algorithme_de_recherche_en_JavaScript_4, Developpez_un_algorithme_de_recherche_en_JavaScript_5, Developpez_un_algorithme_de_recherche_en_JavaScript_6]
        },
        {
            "name": "Débuggez et testez un SaaS RH",
            "img": [Debuggez_et_testez_un_SaaS_RH_1, Debuggez_et_testez_un_SaaS_RH_2, Debuggez_et_testez_un_SaaS_RH_3, Debuggez_et_testez_un_SaaS_RH_4, Debuggez_et_testez_un_SaaS_RH_5, Debuggez_et_testez_un_SaaS_RH_6, Debuggez_et_testez_un_SaaS_RH_7]
        },
        {
            "name": "Définissez les besoins pour une app de soutien scolaire",
            "img": [Definissez_les_besoins_pour_une_app_de_soutien_scolaire_1, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_2, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_3, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_4, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_5, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_6, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_7, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_8, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_9, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_10, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_11, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_12, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_13, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_14, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_15, Definissez_les_besoins_pour_une_app_de_soutien_scolaire_16]
        },
        {
            "name": "Développez une application Web avec React et React Router",
            "img": [Developpez_une_application_Web_avec_React_et_React_Router_1, Developpez_une_application_Web_avec_React_et_React_Router_2, Developpez_une_application_Web_avec_React_et_React_Router_3, Developpez_une_application_Web_avec_React_et_React_Router_4]
        },
        {
            "name": "Développez un tableau de bord analytics avec React",
            "img": [Developpez_un_tableau_de_bord_analytics_avec_React_1, Developpez_un_tableau_de_bord_analytics_avec_React_2]
        },
        {
            "name": "Utilisez une API pour un compte utilisateur bancaire avec React",
            "img": [Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_1, Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_2, Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_3, Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_4, Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_5, Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_6, Utilisez_une_API_pour_un_compte_utilisateur_bancaire_avec_React_7]
        },
        {
            "name": "Faites passer une librairie jQuery vers React",
            "img": [Faites_passer_une_librairie_jQuery_vers_React_1, Faites_passer_une_librairie_jQuery_vers_React_2, Faites_passer_une_librairie_jQuery_vers_React_3, Faites_passer_une_librairie_jQuery_vers_React_4, Faites_passer_une_librairie_jQuery_vers_React_5, Faites_passer_une_librairie_jQuery_vers_React_6]
        },
        {
            "name": "ETS Gaveriaux Raywan",
            "img": [ETS_Gaveriaux_Raywan]
        },
        {
            "name": "Mon Portfolio",
            "img": [Mon_Portfolio]
        }
    ]
};

const nameReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_NAME':
            return {
                ...state,
                name: action.payload
            };
        case 'DEL_NAME':
            return {
                ...state,
                name: ''
            };
        default:
            return state;
    }
};

export default nameReducer;