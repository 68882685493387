import ImageGallery from "./ImageGallery";

export default function Card({classN, info, name}) {
    const className = `${classN}__card`

    return (
        <section style={{filter: name && "blur(8px)", pointerEvents: name && "none"}} className={className}>
            <div>
                <ImageGallery key={info.index} classN={className} pict={info.name}/>
            </div>

            <div>
                {
                    info.education
                    ? (<p><span>Formation :</span><span>{info.education}</span></p>)
                    : (<p><span>Activité :</span><span>{info.activity}</span></p>)
                }
                <p><span>Projet :</span><span>{info.name}</span></p>
                <p><span>Année :</span><span>{info.year}</span></p>
                {
                    info.git
                        ? (<p><span>GitHub :</span><span><a href={info.git}>Cliquez ici</a></span></p>)
                        : (<p><span>Site :</span><span><a href={info.link}>Cliquez ici</a></span></p>)
                }
                <p><span>Technologie :</span><span>{info.lang}</span></p>
                <p><span>Description :</span><span>{info.description}</span></p>
            </div>
        </section>
    )
}