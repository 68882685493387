import home from "../utils/json/home.json"
import Section from "../components/Section";
import {useRef} from "react";
import "../assets/scss/pages/home.scss"


export default function Home() {
    const className = "home"
    const profileSection = useRef(null);

    const scrollDown = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <main className={className}>
            {
                home.map((section) => (
                    <Section key={section.id} classN={`${className}__${section.className}`} data={section.contents} onClick={() => scrollDown(profileSection)} refs={profileSection} />
                ))
            }
        </main>
    )
}