import projects from "../utils/json/projects.json"
import Cards from "../components/portfolio/Cards";
import "../assets/scss/pages/portfolio.scss"
import Title from "../components/Title";

export default function Portfolio() {
    const className = "portfolio"

    return (
        <main className={className}>
            <Title classN={className} content={"PORTFOLIO"} size={"h1"} />
            <Cards classN={className} projects={projects} />
        </main>
    )
}