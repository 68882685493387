import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import './assets/scss/base/base.scss';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./utils/redux/store"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Header from "./components/base/Header";
import Footer from "./components/base/Footer";
import Portfolio from "./pages/Portfolio";
import Home from "./pages/Home";
import Cv from "./pages/Cv";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";



// Les différentes routes
const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/cv",
        element: <Cv />,
    },
    {
        path: "/portfolio",
        element: <Portfolio />,
    },
    {
        path: "/contact",
        element: <Contact />,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <ScrollToTop />
          <Header />
          <RouterProvider router={router} />
          <Footer />
      </Provider>
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();