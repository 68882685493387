import {useEffect, useState} from "react";
import { FaAngleUp } from 'react-icons/fa' ;
import "../assets/scss/layouts/_scrollToTop.scss"

export default function ScrollToTop() {
    const [showTopBtn, setShowTopBtn] = useState(false) ;

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setShowTopBtn(true) ;
            } else {
                setShowTopBtn(false) ;
            }
        }) ;
    }, []) ;

    const goToTop = () => {
        window.scrollTo({
            top : 0,
            behavior : 'smooth',
        }) ;
    } ;

    return (
        <div className="top-to-btm">
            {showTopBtn && (
                <FaAngleUp className="icon-position icon-style" onClick={goToTop} />
            )}
        </div>
    )
}