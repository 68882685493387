import Card from "./Card";
import { useSelector } from 'react-redux';
import Carousel from "./Carousel";

export default function Cards({classN, projects}) {
    const className = `${classN}__cards`
    const name = useSelector(state => state.name);
    const carouselData = useSelector(state => state.carouselData);

    return (
        <div className={className}>
            {
                [...projects].reverse().map(info => <Card classN={className} key={info.name} info={info} name={name} />)
            }

            <Carousel classN={className} name={name} carouselData={carouselData} />
        </div>
    )
}