import Link from "./Link";

export default function Paragraph({classN, content}) {
    const className = `${classN}__paragraph`;

    return (
        <p className={className}>
            {
                content.map((text) => (
                    text.type === "inline"
                    ?
                    text.content
                    :
                    text.type === "link"
                    ?
                    <Link key={text.id} classN={className} link={text.link} content={text.content} />
                    :
                    null
                ))
            }
        </p>
    )
}